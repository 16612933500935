exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loteamentos-industriais-tsx": () => import("./../../../src/pages/loteamentos-industriais.tsx" /* webpackChunkName: "component---src-pages-loteamentos-industriais-tsx" */),
  "component---src-pages-loteamentos-residenciais-tsx": () => import("./../../../src/pages/loteamentos-residenciais.tsx" /* webpackChunkName: "component---src-pages-loteamentos-residenciais-tsx" */),
  "component---src-pages-plotter-tsx": () => import("./../../../src/pages/plotter.tsx" /* webpackChunkName: "component---src-pages-plotter-tsx" */),
  "component---src-pages-sobre-tsx": () => import("./../../../src/pages/sobre.tsx" /* webpackChunkName: "component---src-pages-sobre-tsx" */),
  "component---src-templates-template-plotter-js": () => import("./../../../src/templates/template-plotter.js" /* webpackChunkName: "component---src-templates-template-plotter-js" */),
  "component---src-templates-template-project-industrial-js": () => import("./../../../src/templates/template-project-industrial.js" /* webpackChunkName: "component---src-templates-template-project-industrial-js" */),
  "component---src-templates-template-project-js": () => import("./../../../src/templates/template-project.js" /* webpackChunkName: "component---src-templates-template-project-js" */)
}

